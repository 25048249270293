import React from 'react'
import { images } from '../../constants';
import { BsInstagram, BsFacebook, BsTelegram } from 'react-icons/bs';
import { MdLocationPin } from 'react-icons/md';

import './Footer.scss';

const Footer = () => {
  return (
    <div className='app__footer'>
      {/* Hidden SEO content */}
      <div style={{ 
        position: 'absolute', 
        width: '1px', 
        height: '1px', 
        padding: '0', 
        margin: '-1px', 
        overflow: 'hidden', 
        clip: 'rect(0, 0, 0, 0)', 
        whiteSpace: 'nowrap', 
        border: '0' 
      }} 
      role="presentation">
        <h2>Автошкола Львів – найкращий вибір для майбутніх водіїв</h2>
        <p>
          Шукаєте якісні курси водіння у Львові? Наша автошкола пропонує професійне навчання, 
          яке допоможе вам отримати водійське посвідчення швидко, легко та комфортно. 
          Ми забезпечуємо комплексну підготовку, яка включає теоретичні заняття, 
          практичні уроки водіння та ефективну підготовку до іспитів.
        </p>
        
        <h3>Чому обрати нашу автошколу у Львові?</h3>
        <ul>
          <li>Сучасний підхід до навчання: Ви можете обрати стандартні курси водіїв або скористатися форматом автокурси онлайн.</li>
          <li>Професійні інструктори: Наші викладачі мають багаторічний досвід навчання та допоможуть вам впевнено почуватися за кермом.</li>
          <li>Доступний графік занять: Запис в автошколу здійснюється з урахуванням ваших побажань щодо часу навчання.</li>
          <li>Індивідуальний підхід: Ми враховуємо рівень підготовки кожного учня, пропонуючи уроки водіння, які відповідають вашим потребам.</li>
          <li>Підготовка до іспитів: Ми допоможемо вам не лише навчитися керувати автомобілем, але й успішно скласти всі необхідні тести для отримання прав.</li>
        </ul>

        <h3>Послуги автошколи</h3>
        <ul>
          <li>Курси водіїв у Львові для початківців та тих, хто хоче оновити свої навички.</li>
          <li>Автокурси онлайн у Львові – зручний варіант для тих, хто цінує свій час.</li>
          <li>Практичні уроки водіння у Львові на сучасних автомобілях.</li>
          <li>Допомога в оформленні документів для отримання водійського посвідчення.</li>
        </ul>

        <h3>Як розпочати навчання в автошколі?</h3>
        <p>
          Запис в автошколу доступний прямо на сайті. Залиште заявку, і ми зв'яжемося з вами, 
          щоб узгодити всі деталі. Не відкладайте свою мрію отримати права – зробіть перший крок вже сьогодні!
        </p>
        <p>
          Автошкола у Львові – це ваш шлях до впевненого водіння. Обирайте найкращі автокурси 
          щоб отримати посвідчення водія та ставайте частиною нашої команди успішних водіїв!
        </p>
      </div>

      <div className="app__footer-item">
        <img src={images.logoFooter} alt="logo" loading="lazy"/>
      </div>
      <div className="app__footer-item">
        <a className="app__footer-phone-number" href="tel:+380 (93) 180 1177">+380 (93) 180 1177</a>
        <a className="app__footer-phone-number" href="tel:+380 (98) 180 1177" style={{ marginTop: '10px' }}>+380 (98) 180 1177</a>
      </div>
      <div className="app__footer-item">
        <div className="app__footer-address-block">
          <MdLocationPin size={32} color="#FFFFFF" />
          <a href='https://goo.gl/maps/nKdCkwEYPmba2j6H8' target="_blank" rel="noopener noreferrer" className="app__footer-address">Вул. Г.Мазепи 1Б (ТРЦ спартак)</a>
        </div>
        <div className="app__footer-address-block">
          <MdLocationPin size={32} color="#FFFFFF" />
          <a href='https://goo.gl/maps/SA29J62TX2sfeyoB7' target="_blank" rel="noopener noreferrer" className="app__footer-address">Вул. Академіка Колесси 2 (центр)</a>
        </div>
        <div className="app__footer-address-block">
          <MdLocationPin size={32} color="#FFFFFF" />
          <a href='https://goo.gl/maps/TVQdCMEW9AED5PH38' target="_blank" rel="noopener noreferrer" className="app__footer-address">вул. Наукова 7А (БЦ "ОПТІМА ПЛАЗА")</a>
        </div> 
        <div className="app__footer-address-block">
          <MdLocationPin size={32} color="#FFFFFF" />
          <a href='https://maps.app.goo.gl/RUJ5dQW7UVuGRJmu6?g_st=it' target="_blank" rel="noopener noreferrer" className="app__footer-address">пл. Князя Святослава 5. (жк. "Семицвіт")</a>
        </div>
        <div className="app__footer-address-block">
          <MdLocationPin size={32} color="#FFFFFF" />
          <a href='https://g.co/kgs/J99tvmL' target="_blank" rel="noopener noreferrer" className="app__footer-address">Сихів, вул. Хуторівка, 40а, каб. 212</a>
        </div>
        <div className="app__footer-address-block">
          <MdLocationPin size={32} color="#FFFFFF" />
          <a href='https://maps.app.goo.gl/5xyfiZrxwexbXw4v5' target="_blank" rel="noopener noreferrer" className="app__footer-address" style={{ marginTop: '10px' }}>Вул.Водогінна 2 (Львівводоканал)</a>
        </div>
      </div>
      <div className="app__footer-item app__footer-sn">
        <div className="app__footer-sn-row">
          <a href="https://instagram.com/autoinstruktor.lviv?igshid=YmMyMTA2M2Y=" target="_blank" rel="noreferrer" >
            <BsInstagram size={24} color="white" className='app__footer-sn-item' />
          </a>
          <a href="https://www.facebook.com/people/autoinstructorlviv/100047391673967/" target="_blank" rel="noreferrer">
            <BsFacebook size={24} color="white" className='app__footer-sn-item' />
          </a>
        </div>
        <div className="app__footer-sn-row">
          <a href="https://telegram.me/TAURUS_LVIV" target="_blank" rel="noreferrer">
            <BsTelegram size={24} color="white" className='app__footer-sn-item' />
          </a>
          <a href="viber://chat?number=0938001062" style={{ width: '42px' }} target="_blank" rel="noreferrer">
            <img src={images.viber} className="app__footer-sn-item app__svg-viber" href="viber://chat?number=0938001062" alt="viber" loading="lazy"/>
          </a>
        </div>
        <a id="publicContract" href={images.publicContract} target="_blank">Публічний договір</a>
      </div>
    </div>
  )
}

export default Footer